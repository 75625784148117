<template>
  <div class="dashboard-container Country">
    <SearchQuery :queryParams.sync="queryParams" />
    <div class="dashboard-container-main Client-container">
      <TopN :queryParams.sync="queryParams" />
      <SyntheticTable
        :columes="columes"
        requestUrl="/countryBusinessData/overallQuery"
      />
    </div>
  </div>
</template>

<script>
  import SearchQuery from '../SearchQuery.vue';
  import TopN from './TopN.vue';
  import SyntheticTable from '../SyntheticTable.vue';
  export default {
    name: 'Country',
    components: {
      SearchQuery,
      TopN,
      SyntheticTable,
    },
    data() {
      return {
        queryParams: {
          timeTag: '',
          startDate: '',
          endDate: '',
        },
        columes: [
          { prop: 'countryName', label: '目的国' },
          { prop: 'tradeMode', label: '业务类型' },
          { prop: 'percent', label: '占比(%)' },
          { prop: 'num', label: '单量/单' },
          { prop: 'price', label: '金额/万元' },
          { prop: 'currencyName', label: '币制' },
          // { prop: 'num9610', label: '9610出口（单）' },
          // { prop: 'num9710', label: '9710出口（单）' },
          // { prop: 'num9810', label: '9810出口（单）' },
          // { prop: 'price9610', label: '9610出口（万元）' },
          // { prop: 'price9710', label: '9710出口（万元）' },
          // { prop: 'price9810', label: '9810出口（万元）' },
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>
.Country{
}
</style>